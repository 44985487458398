/**
 * Export Schema of NntTag
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet} from '@simpli/meta-schema'
import {NntTag} from '@/model/resource/NntTag'

/* TODO: review generated schema */
export class ExportNntTagSchema extends DefaultSchema {
  readonly name = 'ExportNntTag'

  readonly fieldSet: FieldSet<NntTag> = {
    idNntTagPk: schema => schema.model.idNntTagPk,
    title: schema => schema.model.title,
    active: schema => $.filter.bool(schema.model.active),
  }
}
